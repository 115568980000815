<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('fertilizerSales.badc_allocation_view') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered style="font-size:12px;color:#555555;">
                  <tbody>
                    <tr>
                      <th>{{ $t('globalTrans.division') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.division_bn : formData.division }}</td>
                      <th>{{ $t('globalTrans.region') }}</th>
                      <td style="width:25%">{{ $i18n.locale === 'bn' ? formData.region_bn : formData.region }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('globalTrans.district') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.district_bn : formData.district }}</td>
                      <th>{{ $t('globalTrans.fiscalYear') }}</th>
                      <td>
                        {{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('fertilizerSales.month') }}</th>
                      <td>{{ $i18n.locale === 'bn' ? formData.month_bn : formData.month_en }}</td>
                      <th>{{ $t('fertilizerSales.other_district_warehouse') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.odistrict : formData.odistrict_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('movement.allocationDate') }}</th>
                      <td>{{ formData.allocation_date | dateFormat }}</td>
                      <th>{{ $t('fertilizerSales.allocationType') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.allocation : formData.allocation_bn }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('fertilizerSales.sale_to') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.saleTo : formData.saleTo_bn }}</td>
                      <th>{{$t('fertilizerSales.memo_no') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.memo_no : formData.memo_no }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('fertilizerSales.clause') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.clause : formData.clause }}</td>
                      <th>{{ $t('fertilizerSales.comment') }}</th>
                      <td>{{ currentLocale === 'en' ? formData.comments : formData.comments_bn }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row>
             <b-col lg="12" sm="12">
                <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                  <b-thead head-variant="secondary">
                      <b-tr>
                          <b-th colspan="3" rowspan="2" class="text-center">{{ $t('movement.warehouseName') }}</b-th>
                          <b-th :colspan="fertilizerNameList.length" class="text-center">{{ $t('movement.fertilizerName') }}{{ ' [' + $t('fertilizerSales.m_ton') + ']'}}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" class="text-center">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                      </b-tr>
                    <b-tr>
                          <b-td colspan="3" :rowspan="selectedGodown.length"><span v-for="(godown, index) in selectedGodown" :key="index" class="text-center">{{ $i18n.locale === 'bn' ? godown.text_bn : godown.text_en }}{{ '/' }}</span></b-td>
                          <b-td v-for="(fertilizer, index) in fertilizerNameList" :key="index" :rowspan="selectedGodown.length" class="text-center">
                              {{ $n(formData.fertilizer_info[index].quantity) }}
                          </b-td>
                    </b-tr>
                  </b-thead>
                </b-table-simple>
             </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
// import { lcList, lcInfo, programManagementShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    var fertilizerData = []
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.tempData = tmp
      if (tmp) {
        this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
          const qty = tmp.badc_allocation_details.find(doc => doc.fertilizer_id === fertilizer.value)
            const item = {
                fertilizer_id: fertilizer.value,
                fertilizer_name: fertilizer.text,
                quantity: qty ? qty.quantity : null
                }
            const fer = ''
            this.info.fertilizer.push(fer)
            fertilizerData.push(item)
        })
        this.formData.fertilizer_info = fertilizerData
        const result = [...new Map(this.formData.badc_allocation_details.map(x => [x.godown_id, x])).values()]
        const list = []
        result.forEach((godown, index) => {
          const go = this.godownInfoList.find(doc => doc.value === godown.godown_id)
          if (go) {
            list.push(go)
          }
        })
        this.selectedGodown = list
      }
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        district_id: 0,
        region_id: 0,
        division_id: 0,
        allocation_date: '',
        fertilizer_info: [],
        godown_info: [],
        fiscal_year_id: 0,
        allocation_type_id: 0,
        other_district_id: 0,
        month: 0,
        sale_to: 0,
        memo_no: '',
        clause: '',
        godown: [],
        total: [],
        comments: '',
        comments_bn: ''
      },
            info: {
          fertilizer: []
      },
      godown_info: [],
      index: '',
      ministry: [],
      districtList: [],
      selectedGodown: []
    }
  },
  computed: {
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionList: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
    saleToList: function () {
      const list = [
        { value: 1, text: 'Dealer' },
        { value: 2, text: 'Farm Division' },
        { value: 3, text: 'Horticulture Development Division' },
        { value: 4, text: 'Potato Seed Division' },
        { value: 5, text: 'Vegetable Seed Division' }
      ]
      const listbn = [
        { value: 1, text: 'ব্যবসায়ী' },
        { value: 2, text: 'খামার বিভাগ' },
        { value: 3, text: 'উদ্যান উন্নয়ন বিভাগ' },
        { value: 4, text: 'আলু বীজ বিভাগ' },
        { value: 5, text: 'সবজি বীজ বিভাগ' }
      ]
      return this.$i18n.locale === 'bn' ? listbn : list
    },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    pdfExport () {
    const reportTitle = this.$t('fertilizerSales.badc_allocation_view')
    ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.fertilizerNameList, this.selectedGodown)
    }
  }
}
</script>

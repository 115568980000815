<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Division"  vid="division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.division')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.division_id"
                                :options="divisionList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Region"  vid="region_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="region_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.region')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.region_id"
                                :options="officeList"
                                id="region_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="District"  vid="district_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.district')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.month')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.month"
                                :options="monthList"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.allocation_type_id"
                                :options="allocationTypeList"
                                id="allocation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Date" vid="allocation_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.allocationDate')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input class="form-control"
                                v-model="formData.allocation_date"
                                placeholder="Select Date"
                                id="from_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Sale To"  vid="sale_to" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="sale_to"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.sale_to')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.sale_to"
                                :options="saleToList"
                                id="sale_to"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                         <b-col lg="6" sm="12">
                          <ValidationProvider name="Memo No"  vid="memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="memo_no"
                                  v-model="formData.memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Clause"  vid="clause" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="clause"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.clause')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="clause"
                                  v-model="formData.clause"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Other District Warehouse"  vid="other_district_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="other_district_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.other_district_warehouse')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.other_district_id"
                                :options="districtList"
                                id="other_district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                      <b-thead head-variant="secondary">
                          <b-tr>
                              <b-th colspan="2" rowspan="2" class="text-center">{{ $t('movement.warehouseName') }}</b-th>
                              <b-th :colspan="fertilizerNameList.length" class="text-center">{{ $t('movement.fertilizerName') }}{{ ' [' + $t('fertilizerSales.m_ton') + ']'}}</b-th>
                          </b-tr>
                          <b-tr>
                              <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" class="text-center">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }} {{ '[' + $n(info.fertilizer[index]) + ']' }}</b-th>
                          </b-tr>
                        <b-tr>
                              <b-th colspan="2" :rowspan="selectedGodown.length"><span v-for="(godown, index) in selectedGodown" :key="index" class="text-center">{{ $i18n.locale === 'bn' ? godown.text_bn : godown.text_en }} <b-button variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-close-line m-0"></i></b-button><br></span></b-th>
                              <b-th v-for="(fertilizer, index) in fertilizerNameList" :key="index" :rowspan="selectedGodown.length" class="align-middle">
                                <b-form-input
                                  :id="'fertilizer_' + (index+1)"
                                  :ref="'fertilizer_' + (index+1)"
                                  v-model="formData.fertilizer_info[index].quantity"
                                  @keyup="getValidity"
                              ></b-form-input>
                              </b-th>
                        </b-tr>
                      </b-thead>
                    </b-table-simple>
                    <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Comment"  vid="comment" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="comment"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.comment')}} <span class="text-danger"></span>
                              </template>
                              <b-form-input
                                  id="comment"
                                  v-model="formData.comments"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Comment (Bn)"  vid="comment_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="comment"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.commentsBn')}} <span class="text-danger"></span>
                              </template>
                              <b-form-input
                                  id="comment"
                                  v-model="formData.comments_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{fertilizerNameList}}</pre> -->
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { badcAllocationStore, badcAllocationUpdate, getMinistryAllocation } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
     var fertilizerData = []
    if (this.id) {
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.ministry = this.getMinistryAllocation(tmp.allocation_type_id)
      var godownInfo = []
      Object.assign(this.formData, godownInfo)
      this.tempData = tmp
      if (tmp) {
        this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
          const qty = tmp.badc_allocation_details.find(doc => doc.fertilizer_id === fertilizer.value)
            const item = {
                fertilizer_id: fertilizer.value,
                fertilizer_name: fertilizer.text,
                quantity: qty ? qty.quantity : null
                }
            const fer = ''
            this.info.fertilizer.push(fer)
            fertilizerData.push(item)
        })
        this.formData.fertilizer_info = fertilizerData
        const result = [...new Map(this.formData.badc_allocation_details.map(x => [x.godown_id, x])).values()]
        const list = []
        result.forEach((godown, index) => {
          const go = this.godownInfoList.find(doc => doc.value === godown.godown_id)
          if (go) {
            list.push(go)
          }
        })
        this.selectedGodown = list
      }
    } else {
      this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
          const item = {
              fertilizer_id: fertilizer.value,
              fertilizer_name: fertilizer.text,
              quantity: ''
          }
          const fer = ''
          this.info.fertilizer.push(fer)
          fertilizerData.push(item)
      })
      this.formData.fertilizer_info = fertilizerData
    }
  },
  mounted () {
    flatpickr('#from_date', {})
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        district_id: 0,
        region_id: 0,
        division_id: 0,
        allocation_date: '',
        fertilizer_info: [],
        godownInfo: [],
        fiscal_year_id: 0,
        allocation_type_id: 0,
        other_district_id: 0,
        month: 0,
        sale_to: 0,
        memo_no: '',
        clause: '',
        godown: [],
        total: [],
        comments: '',
        comments_bn: ''
      },
      info: {
          fertilizer: []
      },
      index: '',
      ministry: [],
      districtList: [],
      selectedGodown: []
    }
  },
  computed: {
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    officeList: function () {
        return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    saleToList: function () {
      const list = [
        { value: 1, text: 'Dealer' },
        { value: 2, text: 'Farm Division' },
        { value: 3, text: 'Horticulture Development Division' },
        { value: 4, text: 'Potato Seed Division' },
        { value: 5, text: 'Vegetable Seed Division' }
      ]
      const listbn = [
        { value: 1, text: 'ব্যবসায়ী' },
        { value: 2, text: 'খামার বিভাগ' },
        { value: 3, text: 'উদ্যান উন্নয়ন বিভাগ' },
        { value: 4, text: 'আলু বীজ বিভাগ' },
        { value: 5, text: 'সবজি বীজ বিভাগ' }
      ]
      return this.$i18n.locale === 'bn' ? listbn : list
    },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'formData.district_id': function (newVal, oldVal) {
      if (!this.id) {
        const newList = this.godownInfoList.filter(doc => doc.district_id === newVal)
        this.selectedGodown = newList
      }
    },
    'formData.other_district_id': function (newVal, oldVal) {
      if (!this.id) {
        const secondList = this.godownInfoList.filter(doc => doc.district_id === newVal)
        const mixList = this.selectedGodown.concat(secondList)
        this.selectedGodown = mixList
      }
    },
    'formData.allocation_type_id': function (newVal, oldVal) {
      if (!this.id) {
        this.ministry = this.getMinistryAllocation(newVal)
      }
    }

  },
  methods: {
    remove (index) {
        this.selectedGodown.splice(index, 1)
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.selectedGodown.forEach((item, index) => {
        this.formData.godownInfo.push(item.value)
      })
      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${badcAllocationUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, badcAllocationStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    async getMinistryAllocation (type) {
        await RestApi.getData(seedFertilizerServiceBaseUrl, getMinistryAllocation, this.formData).then(response => {
          if (response.success) {
              this.info.fertilizer = []
              this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
                    const fer = response.data.find(doc => doc.fertilizer_id === fertilizer.value)
                    this.info.fertilizer.push(fer.quantity)
                })
            } else {
            this.info.fertilizer = []
              this.fertilizerNameList.forEach((fertilizer, fertilizerIndex) => {
                    this.info.fertilizer.push(0)
                })
              this.$toast.error({
                title: 'warning',
                message: response.message,
                color: '#D6E09B'
              })
            }
        })
    },
    getValidity (event) {
      this.fertilizerNameList.forEach((item, index) => {
        const id = '#fertilizer_' + (index + 1)
        const currentId = event.target.id
        const currentIndex = parseInt(currentId.replace('fertilizer_', '')) - 1
        if (currentIndex === index) {
           const idValAmount = document.querySelector(id).value
           if (idValAmount > this.info.fertilizer[index]) {
               const remainingIdVal = Math.floor(idValAmount / 10)
                document.querySelector(id).value = remainingIdVal
           }
        }
      })
    }
  }
}
</script>

<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                   <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.month')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.month"
                                :options="monthList"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.allocationType')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.allocation_type_id"
                                :options="allocationTypeList"
                                id="allocation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="District"  vid="district_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.district')}}  <span class="text-danger"></span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    <b-col class="col-sm-2 col-md-12">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerSales.badc_allocation')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                        <!-- <b-button variant="danger" v-b-modal.modal-5>
                             {{  $t('globalTrans.excelImport') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(po_memo_no)="data">
                                          {{ $t(data.item.po_memo_no, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(allocation_date)="data">
                                          {{ data.item.allocation_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(lc_date)="data">
                                          {{ $n(parseInt(data.item.lc_date.split('-')[0])) + '-' +  $n(data.item.lc_date.split('-')[1]) + '-' + $n(data.item.lc_date.split('-')[2]) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.draft')}}</span>
                                            <span class="badge badge-success" v-else>{{$t('globalTrans.save')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-eye"></i></b-button>
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="data.item.status == 1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)" v-if="data.item.status == 1"><i class="ri-delete-bin-line m-0"></i></b-button>
                                            <b-button variant=" iq-bg-info" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)"><i class="far fa-check-square m-0"></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 size="sm" @click="edit(data.item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)" v-if="data.item.status === 1">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Delete" size="sm" @click="remove(data.item)"  v-if="data.item.status === 1">
                                                <i class="ri-delete-bin-line"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Final Record" size="sm" @click="finalSave(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-check"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('fertilizerSales.badc_allocation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
            {{ $t('fertilizerSales.badc_allocation') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { badcAllocationList, badcAllocationFinalSave, badcAllocationDestroy } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                id: 0,
                fiscal_year_id: 0,
                month: 0,
                allocation_type_id: 0,
                district_id: 0
            }
        }
    },
    computed: {
        divisionList: function () {
          return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        regionList: function () {
            return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
        },
        districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        },
        fiscalYearList: function () {
          const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
        saleToList: function () {
          const list = [
              { value: 1, text: 'Dealer' },
              { value: 2, text: 'Farm Division' },
              { value: 3, text: 'Horticulture Development Division' },
              { value: 4, text: 'Potato Seed Division' },
              { value: 5, text: 'Vegetable Seed Division' }
            ]
          const listbn = [
              { value: 1, text: 'ব্যবসায়ী' },
              { value: 2, text: 'খামার বিভাগ' },
              { value: 3, text: 'উদ্যান উন্নয়ন বিভাগ' },
              { value: 4, text: 'আলু বীজ বিভাগ' },
              { value: 5, text: 'সবজি বীজ বিভাগ' }
            ]
          return this.$i18n.locale === 'bn' ? listbn : list
        },
        allocationTypeList: function () {
          const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
          const tempData = type.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
        monthList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
        },
        fertilizerNameList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
        },
        formTitle () {
            return this.editItemId === 0 ? this.$t('fertilizerSales.badc_allocation_add') : this.$t('fertilizerSales.badc_allocation_edit')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.fiscalYear'), class: 'text-center' },
                { label: this.$t('fertilizerSales.month'), class: 'text-center' },
                { label: this.$t('fertilizerSales.allocationType'), class: 'text-center' },
                { label: this.$t('globalTrans.district'), class: 'text-center' },
                { label: this.$t('movement.allocationDate'), class: 'text-center' },
                // { label: this.$t('globalTrans.status'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year_bn' },
                { key: 'month_bn' },
                { key: 'allocation_bn' },
                { key: 'district_bn' },
                { key: 'allocation_date' },
                { key: 'action' },
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'month_en' },
                { key: 'allocation' },
                { key: 'district' },
                { key: 'allocation_date' },
                { key: 'action' },
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        remove (item) {
            this.toggleStatusCustom(seedFertilizerServiceBaseUrl, badcAllocationDestroy, item)
        },
        finalSave (item) {
          this.toggleStatusCustom2(seedFertilizerServiceBaseUrl, badcAllocationFinalSave, item)
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, badcAllocationList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        monthListFn () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const fiscalObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalData = {}
            if(typeof fiscalObj !== 'undefined') {
              fiscalData.fiscal_year = fiscalObj.text_en
              fiscalData.fiscal_year_bn = fiscalObj.text_bn
            } else {
              fiscalData.fiscal_year = ''
              fiscalData.fiscal_year_bn = ''
            }
            const monthObj = this.monthListFn().find(doc => doc.value === parseInt(item.month))
            const monthData = {}
            if(typeof monthObj !== 'undefined') {
              monthData.month_en = monthObj.text_en
              monthData.month_bn = monthObj.text_bn
            } else {
              monthData.month_en = ''
              monthData.month_bn = ''
            }
            const allocationObj = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.find(allocationTypeList => allocationTypeList.value === item.allocation_type_id)
            const allocationData = {}
            if(typeof allocationObj !== 'undefined') {
              allocationData.allocation = allocationObj.text_en
              allocationData.allocation_bn = allocationObj.text_bn
            } else {
              allocationData.allocation = ''
              allocationData.allocation_bn = ''
            }

            const districtObj = this.districtList.find(districtList => districtList.value === item.district_id)
            const districtData = {}
            if(typeof districtObj !== 'undefined') {
              districtData.district = districtObj.text_en
              districtData.district_bn = districtObj.text_bn
            } else {
              districtData.district = ''
              districtData.district_bn = ''
            }

            const odistrictObj = this.districtList.find(districtList => districtList.value === item.other_district_id)
            const odistrictData = {}
            if(typeof odistrictObj !== 'undefined') {
              odistrictData.odistrict = odistrictObj.text_en
              odistrictData.odistrict_bn = odistrictObj.text_bn
            } else {
              odistrictData.odistrict = ''
              odistrictData.odistrict_bn = ''
            }

            const divisionObj = this.divisionList.find(divisionList => divisionList.value === item.division_id)
            const divisionData = {}
            if(typeof divisionObj !== 'undefined') {
              divisionData.division = divisionObj.text_en
              divisionData.division_bn = divisionObj.text_bn
            } else {
              divisionData.division = ''
              divisionData.division_bn = ''
            }

            const regionObj = this.regionList.find(regionList => regionList.value === item.region_id)
            const regionData = {}
            if(typeof regionObj !== 'undefined') {
              regionData.region = regionObj.text_en
              regionData.region_bn = regionObj.text_bn
            } else {
              regionData.region = ''
              regionData.region_bn = ''
            }

            const saleToObj = this.saleToList.find(saleToList => saleToList.value === item.sale_to)
            const saleToData = {}
            if(typeof saleToObj !== 'undefined') {
              saleToData.saleTo = saleToObj.text
              saleToData.saleTo_bn = saleToObj.text
            } else {
              saleToData.saleTo = ''
              saleToData.saleTo_bn = ''
            }

            return Object.assign({}, item, fiscalData, monthData, allocationData, districtData, divisionData, regionData, saleToData, odistrictData)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>

import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, fertilizer, godown) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        // const len = fertilizer.length
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  { text: vm.$t('globalTrans.division'), style: 'td' },
                  { text: vm.$i18n.locale === 'bn' ? data.division_bn : data.division, style: 'td' },
                  { text: vm.$t('globalTrans.region'), style: 'td' },
                  { text: vm.$i18n.locale === 'bn' ? data.region_bn : data.region, style: 'td' }
                ],
                [
                  { text: vm.$t('globalTrans.district'), style: 'td' },
                  { text: vm.$i18n.locale === 'bn' ? data.district_bn : data.district, style: 'td' },
                  { text: vm.$t('globalTrans.fiscalYear'), style: 'td' },
                  { text: (i18n.locale === 'bn') ? data.fiscal_year_bn : data.fiscal_year, style: 'td' }
                ],
                [
                  { text: vm.$t('fertilizerSales.month'), style: 'td' },
                  { text: (i18n.locale === 'bn') ? data.month_bn : data.month_en, style: 'td' },
                  { text: vm.$t('fertilizerSales.other_district_warehouse'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.odistrict : data.odistrict_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('movement.allocationDate'), style: 'td' },
                  { text: dateFormat(data.allocation_date), style: 'td' },
                  { text: vm.$t('fertilizerSales.allocationType'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.allocation : data.allocation_bn, style: 'td' }
                ],
                [
                  { text: vm.$t('fertilizerSales.sale_to'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.saleTo : data.saleTo_bn, style: 'td' },
                  { text: vm.$t('fertilizerSales.memo_no'), style: 'td' },
                  { text: data.memo_no, style: 'td' }
                ],
                [
                  { text: vm.$t('fertilizerSales.clause'), style: 'td' },
                  { text: data.clause, style: 'td' },
                  { text: vm.$t('fertilizerSales.comment'), style: 'td' },
                  { text: (i18n.locale === 'en') ? data.comments : data.comments_bn, style: 'td' }
                ]
              ]
            }
          }
        )
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        var tabledata1 = [
            { text: vm.$t('movement.warehouseName'), style: 'th', colSpan: 3, rowSpan: 2, alignment: 'center' },
            {},
            {},
            { text: vm.$t('movement.fertilizerName') + ' [' + vm.$t('fertilizerSales.m_ton') + ']', style: 'th', colSpan: 3, alignment: 'center' },
            {},
            {}
          ]
        var tableRows = []
        tableRows.push(tabledata1)

        var tabledata = [{ text: '', style: 'td', colSpan: 3, alignment: 'center' }, {}, {}]
        fertilizer.map(doc => {
            const txt = { text: (i18n.locale === 'bn') ? doc.text_bn : doc.text_en, style: 'td', alignment: 'right' }
            tabledata.push(txt)
        })
        tableRows.push(tabledata)

        var txts = ''
        godown.map(doc => {
            txts += (i18n.locale === 'bn') ? doc.text_bn : doc.text_en + '/'
        })
        var tabledata2 = [{ text: txts, style: 'td', colSpan: 3, alignment: 'center' }, {}, {}]
        fertilizer.forEach((doc, index) => {
          const txt = { text: vm.$n(data.fertilizer_info[index].quantity), style: 'td', alignment: 'right' }
          tabledata2.push(txt)
        })
        tableRows.push(tabledata2)
        pdfContent.push(
            {
              table: {
                  headerRows: 2,
                  style: 'header2',
                  widths: '*',
                  body: tableRows
              }
            }
        )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('badc-allocation')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
